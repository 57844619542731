export const translations = {
  removerCentro: {
    pt: 'Ao remover o Centro selecionado todas as Notas ficiais vinculadas, não serão mais exibidas na plataforma. Deseja realmente remover?',
    en: 'When removing the selected Center, all linked Official Notes will no longer be displayed on the platform. Do you really want to remove?',
    de: 'Wenn Sie das ausgewählte Zentrum entfernen, werden alle verknüpften offiziellen Notizen nicht mehr auf der Plattform angezeigt. Möchten Sie wirklich entfernen?',
    es: 'Al eliminar el Centro seleccionado, todas las Notas Oficiales vinculadas ya no se mostrarán en la plataforma. ¿Realmente quieres eliminar?',
  },
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  editarCentro: {
    pt: 'Editar Centro',
    en: 'Edit Center',
    de: 'Zentrum bearbeiten',
    es: 'Centro de edición',
  },
  codigoCentro: {
    pt: 'Código Centro',
    en: 'Code Center',
    de: 'Codezentrum',
    es: 'Código Centro',
  },
  nomeFantasia: {
    pt: 'Nome Fantasia',
    en: 'Fantasy name',
    de: 'Fantasiename',
    es: 'Nombre de fantasía',
  },
  inscricaoEstatual: {
    pt: 'Inscrição Estadual',
    en: 'State registration',
    de: 'Staatliche Registrierung',
    es: 'Registro estatal',
  },
  telefone: {
    pt: 'Telefone',
    en: 'Telephone',
    de: 'Telefon',
    es: 'Teléfono',
  },
  cnpj: {
    pt: 'CNPJ',
    en: 'CNPJ',
    de: 'CNPJ',
    es: 'CNPJ',
  },
  razaoSocial: {
    pt: 'Razão Social',
    en: 'Social Reason',
    de: 'Soziale Vernunft',
    es: 'Razón Social',
  },
  uf: {
    pt: 'UF',
    en: 'Federative unit',
    de: 'Föderative Einheit',
    es: 'Unidad federativa',
  },
  lougradouro: {
    pt: 'Logradouro',
    en: 'Public place',
    de: 'Öffentlicher Platz',
    es: 'Lugar público',
  },
  numero: {
    pt: 'Número',
    en: 'Number',
    de: 'Nummer',
    es: 'Número',
  },
  bairro: {
    pt: 'Bairro',
    en: 'Neighborhood',
    de: 'Gegend',
    es: 'Barrio',
  },
  cidade: {
    pt: 'Cidade',
    en: 'City',
    de: 'Stadt',
    es: 'Ciudad',
  },
  latitude: {
    pt: 'Latitude',
    en: 'Latitude',
    de: 'Breite',
    es: 'Latitud',
  },
  longitude: {
    pt: 'Longitude',
    en: 'Longitude',
    de: 'Längengrad',
    es: 'Longitud',
  },
  email: {
    pt: 'E-mail',
    en: 'E-mail',
    de: 'E-mail',
    es: 'Correo electrónico',
  },
  horaCorte: {
    pt: 'Hora de corte',
    en: 'Cutting time',
    de: 'Schnittzeit',
    es: 'Tiempo de corte',
  },
  horaCorteTotal: {
    pt: 'Hora de corte carga total',
    en: 'Full load cut-off time',
    de: 'Abschaltzeit bei Volllast',
    es: 'Tiempo de corte a plena carga',
  },
  inicioAgendamento: {
    pt: 'Início do agendamento',
    en: 'Schedule start',
    de: 'Planbeginn',
    es: 'Horario de inicio',
  },
  cep: {
    pt: 'Cep',
    en: 'Zip code',
    de: 'Postleitzahl',
    es: 'Código postal',
  },
  limparFiltro: {
    pt: 'Limpar Filtro',
    en: 'Clear Filter',
    de: 'Klarfilter',
    es: 'Filtro limpio',
  },
  freteValor: {
    pt: 'Frete Valor',
    en: 'Shipping Value',
    de: 'Versandwert',
    es: 'Valor de envío',
  },
  fretPeso: {
    pt: 'Frete Peso',
    en: 'Shipping Weight',
    de: 'Versandgewicht',
    es: 'Peso de envío',
  },
  seguro: {
    pt: 'Seguro',
    en: 'Safe',
    de: 'Sicher',
    es: 'Seguro',
  },
  pedagio: {
    pt: 'Pedágio',
    en: 'Toll',
    de: 'Maut',
    es: 'Peaje',
  },
  tipo: {
    pt: 'Tipo',
    en: 'Type',
    de: 'Typ',
    es: 'Escribe',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'kündiger',
    es: 'Cancelar',
  },
  filtrar: {
    pt: 'Filtrar',
    en: 'Filter',
    de: 'Filter',
    es: 'Filtrar',
  },
  voltar: {
    pt: 'Voltar',
    en: 'Go back',
    de: 'Geh zurück',
    es: 'Regresa',
  },
  cadastrar: {
    pt: 'Salvar',
    en: 'Save',
    de: 'Speichern',
    es: 'Ahorrar',
  },
  adicionar: {
    pt: 'Adicionar',
    en: 'Add',
    de: 'Hinzufügen',
    es: 'Añadir',
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'Auswählen...',
    es: 'Seleccione...',
  },
  cnpjInvalido: {
    pt: 'CNPJ Inválido',
    en: 'Invalid CNPJ',
    de: 'Ungültiger CNPJ',
    es: 'CNPJ no válido',
  },
  emailInvalido: {
    pt: 'Email inválido',
    en: 'Invalid email',
    de: 'Ungültige EMail',
    es: 'Email inválido',
  },
  CEPnaoEncontrado: {
    pt: 'CEP não encontrado',
    en: 'CEP not found',
    de: 'CEP nicht gefunden',
    es: 'CEP no encontrado',
  },
  transportadoraAdicionada: {
    pt: 'Transpostadora já adicionada',
    en: 'Carrier already added',
    de: 'Mobilfunkanbieter bereits hinzugefügt',
    es: 'Transportista ya agregado',
  },
  obrigatorio: {
    pt: 'Obrigtório',
    en: 'Mandatory',
    de: 'Verpflichtend',
    es: 'Obligatorio',
  },
  roterizacao: {
    pt: 'Roteirização Automática',
    en: 'Automatic Routing',
    de: 'Automatisches Routing',
    es: 'Enrutamiento automático',
  },
  gerenciaCargas: {
    pt: 'Gerencia Cargas',
    en: 'Cargo Management',
    de: 'Frachtmanagement',
    es: 'Gestión de carga',
  },
  transportadora: {
    titulo: {
      pt: 'Transportadoras',
      en: 'Carriers',
      de: 'Transporte',
      es: 'Transportadoras',
    },
    subTitulo: {
      pt: 'Adicione ou remova transporadoras para este centro.',
      en: 'Add or remove carriers for this center.',
      de: 'Fügen Sie oder entfernen Sie Transporte für dieses Zentrum.',
      es: 'Agregue o quite transportadoras para este centro.',
    },
  },
};