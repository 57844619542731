export const translations = {
  cnpjInvalido: {
     pt: 'CNPJ Inválido',
     en: 'Invalid CNPJ',
     de: 'Ungültiger CNPJ',
     es: 'CNPJ no válido',
  },
  obrigatorio: {
     pt: 'Obrigtório',
     en: 'Mandatory',
     de: 'Verpflichtend',
     es: 'Obligatorio',
  },
  cnpj: {
     pt: 'CNPJ',
     en: 'CNPJ',
     de: 'CNPJ',
     es: 'CNPJ',
  },
  idExterior: {
    pt: 'ID Exterior',
    en: 'Foreign ID',
    de: 'Ausländischer Ausweis',
    es: 'DNI extranjero',
  },
  emailInvalido: {
     pt: 'Email inválido',
     en: 'Invalid email',
     de: 'Ungültige EMail',
     es: 'Email inválido',
  },
  codigoInvalido: {
     pt: 'Código inválido',
     en: 'Invalid code',
     de: 'Ungültiger Code',
     es: 'Codigo invalido',
  },
  CEPnaoEncontrado: {
     pt: 'CEP não encontrado',
     en: 'CEP not found',
     de: 'CEP nicht gefunden',
     es: 'CEP no encontrado',
  },
  CEPInvalido: {
     pt: 'CEP inválido',
     en: 'Invalid zip code',
     de: 'Ungültige Postleitzahl',
     es: 'Código postal no válido',
  },
 configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  novoCliente: {
    pt: 'Novo cliente',
    en: 'New customer',
    de: 'Neukunde',
    es: 'Nuevo cliente',
  },
  voltar: {
    pt: 'Voltar',
    en: 'Go back',
    de: 'Geh zurück',
    es: 'Regresa',
  },
  estrangeiro: {
    pt: 'Estrangeiro',
    en: 'Foreign',
    de: 'Ausländisch',
    es: 'Extranjero',
  },
  nacional: {
    pt: 'Nacional',
    en: 'Nacional',
    de: 'National',
    es: 'Nacional',
  },
  razaoSocial: {
    pt: 'Razão Social',
    en: 'Social Reason',
    de: 'Soziale Vernunft',
    es: 'Razón Social',
  },
  nomeFantasia: {
    pt: 'Nome Fantasia',
    en: 'Fantasy name',
    de: 'Fantasiename',
    es: 'Nombre de fantasía',
  },
  codigoSAP: {
    pt: 'Código SAP',
    en: 'SAP code',
    de: 'SAP Code',
    es: 'código SAP',
  },
  segmentacao: {
    pt: 'Customer segmentation',
    en: 'Customer segmentation',
    de: 'Kundensegmentierung',
    es: 'Segmentación de clientes',
  },
  inscricaoEstatual: {
    pt: 'Inscrição Estadual',
    en: 'State registration',
    de: 'Staatliche Registrierung',
    es: 'Registro estatal',
  },
  telefone: {
    pt: 'Telefone',
    en: 'Telephone',
    de: 'Telefon',
    es: 'Teléfono',
  },
  email: {
    pt: 'Email',
    en: 'Email',
    de: 'Bezeichnung',
    es: 'Correo electrónico',
  },
  cep: {
    pt: 'Cep',
    en: 'Zip code',
    de: 'Postleitzahl',
    es: 'Código postal',
  },
  uf: {
    pt: 'UF',
    en: 'Federative unit',
    de: 'Föderative Einheit',
    es: 'Unidad federativa',
  },
  lougradouro: {
    pt: 'Logradouro',
    en: 'Public place',
    de: 'Öffentlicher Platz',
    es: 'Lugar público',
  },
  numero: {
   pt: 'Número',
   en: 'Number',
   de: 'Nummer',
   es: 'Número',
  },
  bairro: {
   pt: 'Bairro',
   en: 'Neighborhood',
   de: 'Gegend',
   es: 'Barrio',
  },
  cidade: {
   pt: 'Cidade',
   en: 'City',
   de: 'Stadt',
   es: 'Ciudad',
  },
  latitude: {
   pt: 'Latitude',
   en: 'Latitude',
   de: 'Breite',
   es: 'Latitud',
  },
  longitude: {
   pt: 'Longitude',
   en: 'Longitude',
   de: 'Längengrad',
   es: 'Longitud',
  },
  necessitaAgendamento: {
    pt: 'Necessita agendamento',
    en: 'Need scheduling',
    de: 'Benötigen Sie eine Terminplanung',
    es: 'Necesita programar',
  },
  infoEntrega: {
    pt: 'Informações de entrega:',
    en: 'Delivery information:',
    de: 'Lieferinformationen:',
    es: 'Información de entrega:',
  },
  segunda:{
    pt: 'Segunda',
    en: 'Monday',
    de: 'Montag',
    es: 'Lunes',
  },
  terca:{
    pt: 'Terça',
    en: 'Tuesday',
    de: 'Dienstag',
    es: 'Martes',
  },
  quarta:{
    pt: 'Quarta',
    en: 'Wednesday',
    de: 'Der Mittwoch',
    es: 'Miércoles',
  },
  quinta:{
    pt: 'Quinta',
    en: 'Thursday',
    de: 'Donnerstag',
    es: 'Jueves',
  },
  sexta:{
    pt: 'Sexta',
    en: 'Friday',
    de: 'Freitag',
    es: 'Viernes',
  },
  sabado:{
    pt: 'Sábado',
    en: 'Saturday',
    de: 'Samstag',
    es: 'Sábado',
  },
  domingo:{
    pt: 'Domingo',
    en: 'Sunday',
    de: 'Sonntag',
    es: 'Domingo',
  },
  inicioRecebimento:{
    pt: 'Início de recebimento',
    en: 'Start of receipt',
    de: 'Beginn des Empfangs',
    es: 'Inicio de recibo',
  },
  fimRecebimento:{
    pt: 'Fim de recebimento',
    en: 'End of receipt',
    de: 'Ende der Quittung',
    es: 'Fin de recibo',
  },
  inicioRefeicao:{
    pt: 'Início de refeição',
    en: 'Meal start',
    de: 'Mahlzeit beginnen',
    es: 'Comienzo de la comida',
  },
  fimRefeicao:{
    pt: 'Fim de refeição',
    en: 'End of meal',
    de: 'Ende der Mahlzeit',
    es: 'Final de la comida',
  },
  observacao: {
    pt: 'Observação',
    en: 'Observation',
    de: 'Überwachung',
    es: 'Observación',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'kündiger',
    es: 'Cancelar',
  },
  cadastrar: {
    pt: 'Salvar',
    en: 'Save',
    de: 'Speichern',
    es: 'Ahorrar',
  },
  editarCliente: {
    pt: 'Editar cliente',
    en: 'Edit customer',
    de: 'Kunde bearbeiten',
    es: 'Editar cliente',
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'Auswählen...',
    es: 'Seleccione...',
  },
  IdExterior: {
    pt: 'Id Exterior',
    en: 'External ID',
    de: 'Externe ID',
    es: 'ID externa',
  }
};