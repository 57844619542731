export const translations = {
  assunto: {
    pt: 'Assunto',
    en: 'Subject matter',
    de: 'Betreff',
    es: 'Tema en cuestion',
  },
  destinatario: {
    pt: 'Destinatário',
    en: 'Recipient',
    de: 'Empfänger',
    es: 'Destinatario',
  },
  dateInicio: {
    pt: 'Data de início',
    en: 'Start date',
    de: 'Startdatum',
    es: 'Fecha de inicio',
  },
  dateFim: {
    pt: 'Data do final',
    en: 'End date',
    de: 'Enddatum',
    es: 'Fecha final',
  },
  limparFiltro: {
    pt: 'Limpar Filtro',
    en: 'Clear Filter',
    de: 'Filter zurücksetzen',
    es: 'Filtro limpio',
  },
  fechar: {
    pt: 'Fechar',
    en: 'Close',
    de: 'Schließen',
    es: 'Cerrar',
  },
  filtrar: {
    pt: 'Filtrar',
    en: 'Filter',
    de: 'Filtern',
    es: 'Filtrar',
  },
  adicionar: {
    pt: 'Adicionar',
    en: 'To add',
    de: 'Hinzufügen',
    es: 'Para agregar',
  },
  remover: {
    pt: 'Remover',
    en: 'Remove',
    de: 'Entfernen',
    es: 'Retirar',
  },
  cadastrar: {
    pt: 'Salvar',
    en: 'Save',
    de: 'Registrieren',
    es: 'Guardar',
  },
  permissao: {
    pt: 'Grupos de permissões',
    en: 'Permission groups',
    de: 'Berechtigungsgruppen',
    es: 'Grupos de permisos',
  },
  selectGrupo: {
    pt: 'Selecione os grupos de permissões adicionais ao usuário',
    en: 'Select additional user permission groups',
    de: 'Wählen Sie die zusätzlichen Berechtigungsgruppen für den Benutzer',
    es: 'Seleccionar grupos de permisos de usuario adicionales',
  },

};