export const translations = {
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  novoAlerta: {
    pt: 'Novo Alerta',
    en: 'New Alert',
    de: 'Neuer Alarm',
    es: 'Nueva alerta',
  },
  cadastrar: {
    pt: 'Salvar',
    en: 'Save',
    de: 'Speichern',
    es: 'Ahorrar',
  },
  remover: {
    pt: 'Remover',
    en: 'Remove',
    de: 'Löschen',
    es: 'Retirar',
  },
  voltar: {
    pt: 'Voltar',
    en: 'Go back',
    de: 'Geh zurück',
    es: 'Regresa',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'kündiger',
    es: 'Cancelar',
  },
  nome: {
    pt: 'Nome',
    en: 'Name',
    de: 'Name',
    es: 'Nombre',
  },
  linhaDenegocio: {
    pt: 'Deseja realmente remover esse nome regional?',
    en: 'Do you really want to remove this regional name?',
    de: 'Möchten Sie diesen regionalen Namen wirklich entfernen?',
    es: '¿Realmente desea eliminar esta nombre regional?',
  },
  editarLinha: {
    pt: 'Editar Business Line',
    en: 'Edit Business Line',
    de: 'Geschäftslinie bearbeiten',
    es: 'Editar línea de negocio',
  },
  setorAtividade: {
    pt: 'Setor de Atividade Divisão',
    en: 'Activity Sector Division',
    de: 'Abteilung für Tätigkeitsbereiche',
    es: 'División de Sector de Actividad',
  },
  descricao: {
    pt: 'Descrição',
    en: 'Description',
    de: 'Beschreibung',
    es: 'Descripción',
  },
  unidade: {
    pt: 'Unidade',
    en: 'Unit',
    de: 'Einheit',
    es: 'Unidad',
  },
};