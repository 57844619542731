export const translations = {
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  obrigatorio: {
    pt: 'Obrigtório',
    en: 'Mandatory',
    de: 'Mandatory',
    es: 'Obligatorio',
  },
  novoCustumer: {
    pt: 'Novo customer segmentation',
    en: 'New customer segmentation',
    de: 'Neue Kundensegmentierung',
    es: 'Nueva segmentación de clientes',
  },
  segmentacao: {
    pt: 'Customer segmentation',
    en: 'Customer segmentation',
    de: 'Kundensegmentierung',
    es: 'Segmentación de clientes',
  },
  voltar: {
    pt: 'Voltar',
    en: 'Go back',
    de: 'Geh zurück',
    es: 'Regresa',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'kündiger',
    es: 'Cancelar',
  },
  cadastrar: {
    pt: 'Cadastrar',
    en: 'Register',
    de: 'Speichern',
    es: 'Register',
  },
  
};