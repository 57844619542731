export const translations = {
  codigo: {
    pt: 'Código',
    en: 'Code',
    de: 'Code',
    es: 'Código',
  },
  email: {
    pt: 'Email',
    en: 'Email',
    de: 'Email',
    es: 'Correo electrónico',
  },
};