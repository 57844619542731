export const translations = {
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  novoTipoveiculo: {
    pt: 'Novo Tipo de Veículo',
    en: 'New Vehicle Type',
    de: 'Neuer Fahrzeugtyp',
    es: 'Nuevo tipo de vehículo',
  },
  obrigatorio: {
    pt: 'Obrigtório',
    en: 'Mandatory',
    de: 'Verpflichtend',
    es: 'Obligatorio',
  },
  voltar: {
    pt: 'Voltar',
    en: 'Go back',
    de: 'Geh zurück',
    es: 'Regresa',
  },
  nome: {
    pt: 'Nome',
    en: 'Name',
    de: 'Name',
    es: 'Nombre',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'kündiger',
    es: 'Cancelar',
  },
  cadastrar: {
    pt: 'Cadastrar',
    en: 'Register',
    de: 'Katastern',
    es: 'Register',
  },
};