export const translations = {
  nome: {
    pt: 'Nome',
    en: 'Name',
    de: 'Name',
    es: 'Nombre',
  },
  deletar: {
    pt: 'Deletar',
    en: 'Delete',
    de: 'Löschen',
    es: 'Borrar',
  },
  email: {
    pt: 'Email',
    en: 'Email',
    de: 'Email',
    es: 'Correo electrónico',
  },
};