export const translations = {
  removerUnidade: {
    pt: 'Deseja realmente removendo essa unidade?',
    en: 'Do you really want to remove that drive?',
    de: 'Wollen Sie dieses Laufwerk wirklich entfernen?',
    es: '¿De verdad quieres quitar esa unidad?',
  },
  editarDivision: {
    pt: 'Editar Division',
    en: 'Edit Division',
    de: 'Division bearbeiten',
    es: 'Editar división',
  },
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
   cadastrar: {
    pt: 'Salvar',
    en: 'Save',
    de: 'Speichern',
    es: 'Ahorrar',
  },
  remover: {
    pt: 'Remover',
    en: 'Remove',
    de: 'Löschen',
    es: 'Retirar',
  },
  voltar: {
    pt: 'Voltar',
    en: 'Go back',
    de: 'Geh zurück',
    es: 'Regresa',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'kündiger',
    es: 'Cancelar',
  },
  nome: {
    pt: 'Nome',
    en: 'Name',
    de: 'Name',
    es: 'Nombre',
  },
};