export const translations = {
  usuarios: {
    pt: 'Usuário',
    en: 'User',
    de: 'Nutzer',
    es: 'Usuário',
  },
  adicionar: {
    pt: 'Adicionar',
    en: 'To add',
    de: 'Hinzufügen',
    es: 'Para agregar',
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'Auswählen...',
    es: 'Seleccione...',
  },
  obrigatorio: {
    pt: 'Obrigtório',
    en: 'Mandatory',
    de: 'Verpflichtend',
    es: 'Obligatorio',
  },
};