export const translations = {
  limparFiltro: {
    pt: 'Limpar Filtro',
    en: 'Clear Filter',
    de: 'Klarfilter',
    es: 'Filtro limpio',
  },
  obrigatorio: {
    pt: 'Obrigtório',
    en: 'Mandatory',
    de: 'Verpflichtend',
    es: 'Obligatorio',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'kündiger',
    es: 'Cancelar',
  },
  filtrar: {
    pt: 'Filtrar',
    en: 'Filter',
    de: 'Filter',
    es: 'Filtrar',
  },
  descricao: {
    pt: 'Descrição',
    en: 'Description',
    de: 'Beschreibung',
    es: 'Descripción',
  },
  codigo: {
    pt: 'Código',
    en: 'Code',
    de: 'Code',
    es: 'Código',
  },
  centro: {
    pt: 'Centro',
    en: 'Center',
    de: 'Center',
    es: 'Centrar',
  },
  peso: {
    pt: 'Peso (Kg)',
    en: 'Weight (Kg)',
    de: 'Gewicht (Kg)',
    es: 'Peso (Kg)',
  },
  duracao: {
    pt: 'Duração (minutos)',
    en: 'Duration (minutes)',
    de: 'Dauer (Minuten)',
    es: 'Duración (minutos)',
  },
  palete: {
    pt: 'Palete',
    en: 'Pallet',
    de: 'Palette',
    es: 'Paleta',
  },
  veiculo: {
    pt: 'Tipo de veículo',
    en: 'Vehicle type',
    de: 'Fahrzeugtyp',
    es: 'Tipo de vehiculo',
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'Auswählen...',
    es: 'Seleccione...',
  },
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  cadastrar: {
    pt: 'Salvar',
    en: 'Save',
    de: 'Speichern',
    es: 'Ahorrar',
  },
  distKM: {
    pt: 'Distancia entre entregas (KM)',
    en: 'Distance between deliveries (KM)',
    de: 'Entfernung zwischen Lieferungen (KM)',
    es: 'Distancia entre entregas (KM)',
  },
  distMaxima: {
    pt: 'Distancia Máxima (KM)',
    en: 'Maximum Distance (KM)',
    de: 'Maximale Entfernung (KM)',
    es: 'Distancia máxima (KM)',
  },
  maximoEntrega: {
    pt: 'Máximo de entregas',
    en: 'Maximum Distance (KM)',
    de: 'Maximale Entfernung (KM)',
    es: 'Distancia máxima (KM)',
  },
  voltar: {
    pt: 'Voltar',
    en: 'Go back',
    de: 'Geh zurück',
    es: 'Regresa',
  },
  editarTipoVeiculo: {
    pt: 'Editar Tipo de Veículo',
    en: 'Edit Vehicle Type',
    de: 'Fahrzeugtyp bearbeiten',
    es: 'Editar tipo de vehículo',
  },
};