export const translations = {
  configuracoes: {
    pt: "Configurações",
    en: "Settings",
    de: "Einstellungen",
    es: "Configuración",
  },
  nome: {
    pt: "Nome",
    en: "Name",
    de: "Name",
    es: "Nombre",
  },
  email: {
    pt: "E-mail",
    en: "E-mail",
    de: "Bezeichnung",
    es: "Correo electrónico",
  },
  tipo: {
    pt: "Tipo",
    en: "Type",
    de: "Typ",
    es: "Escribe",
  },
  selecione: {
    pt: "Selecione...",
    en: "Select...",
    de: "Auswählen...",
    es: "Seleccione...",
  },
  transportadora: {
    pt: "Transportadora",
    en: "Carrier",
    de: "Träger",
    es: "Portador",
  },
  clientes: {
    pt: "Clientes",
    en: "Customers",
    de: "Kunden",
    es: "Clientes",
  },
  obrigatorio: {
    pt: "Obrigtório",
    en: "Mandatory",
    de: "Verpflichtend",
    es: "Obligatorio",
  },
  centro: {
    pt: "Centro",
    en: "Center",
    de: "Center",
    es: "Centrar",
  },
  cancelar: {
    pt: "Cancelar",
    en: "Cancel",
    de: "kündiger",
    es: "Cancelar",
  },
  novoUsuario: {
    pt: "Novo usuário",
    en: "New user",
    de: "Neuer Benutzer",
    es: "Nuevo usuario",
  },
  voltar: {
    pt: "Voltar",
    en: "Go back",
    de: "Geh zurück",
    es: "Regresa",
  },
  cadastrar: {
    pt: "Cadastrar",
    en: "Register",
    de: "Katastern",
    es: "Register",
  },
  renovacaoSenha: {
    pt: "Expiração de senha",
    en: "Password expiration",
    de: "Passwortablauf",
    es: "Expiración de contraseña",
  },
  expiraPlace: {
    pt: "Selecione o intervalo de expiração da senha.",
    en: "Select the password expiration interval.",
    de: "Wählen Sie das Passwort-Ablauf-Intervall aus.",
    es: "Seleccione el intervalo de expiración de la contraseña.",
  },
  naoExpira: {
    pt: "Não expirar senha",
    en: "Do not expire password",
    de: "Passwort nicht ablaufen",
    es: "No expirar contraseña",
  },
  expira30: {
    pt: "Expirar a cada 30 dias",
    en: "Expire every 30 days",
    de: "Jedes 30. Tag ablaufen",
    es: "Expirar cada 30 días",
  },
  expira60: {
    pt: "Expirar a cada 60 dias",
    en: "Expire every 60 days",
    de: "Jedes 60. Tag ablaufen",
    es: "Expirar cada 60 días",
  },
  expira180: {
    pt: "Expirar a cada 180 dias",
    en: "Expire every 180 days",
    de: "Jedes 180. Tag ablaufen",
    es: "Expirar cada 180 días",
  },
  pais: {
    pt: "País",
    en: "Country",
    de: "Land",
    es: "País",
  },
  perfilUsuario: {
    pt: "Perfil de Usuário",
    en: "User Profile",
    de: "Benutzerprofil",
    es: "Perfil de Usuario",
  },
};
