export const translations = {
  editarCustumer: {
    pt: 'Editar customer segmentation',
    en: 'Edit customer segmentation',
    de: 'Kundensegmentierung bearbeiten',
    es: 'Editar segmentación de clientes',
  },
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  segmentacao: {
    pt: 'Customer segmentation',
    en: 'Customer segmentation',
    de: 'Kundensegmentierung',
    es: 'Segmentación de clientes',
  },
  voltar: {
    pt: 'Voltar',
    en: 'Go back',
    de: 'Geh zurück',
    es: 'Regresa',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'kündiger',
    es: 'Cancelar',
  },
  nomeCustomer: {
    pt: 'Nome Customer Segmentation',
    en: 'Edit customer segmentation',
    de: 'Kundensegmentierung bearbeiten',
    es: 'Editar segmentación de clientes',
  },
  cadastrar: {
    pt: 'Salvar',
    en: 'Save',
    de: 'Speichern',
    es: 'Ahorrar',
  },
};