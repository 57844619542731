export const translations = {
  exibindopagina: {
    pt: 'Exibindo página ',
    en: 'Showing page ',
    de: 'Seite anzeigen ',
    es: 'Mostrando página ',
  },
  deUmTotalDe: {
    pt: ' de um total de ',
    en: ' of total ',
    de: ' von insgesamt ',
    es: ' del total ',
  },
  registros: {
    pt: ' registros.',
    en: ' records.',
    de: ' aufzeichnungen.',
    es: ' registros.',
  },
  primeira: {
    pt: 'Primeira',
    en: 'First',
    de: 'Zuerst',
    es: 'Primera',
  },
  ultima: {
    pt: 'Última',
    en: 'Last',
    de: 'Zuletzt',
    es: 'Última',
  },
};