export const translations = {
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  editarAlerta: {
    pt: 'Editar Alerta',
    en: 'Edit Alert',
    de: 'Benachrichtigung bearbeiten',
    es: 'Editar alerta',
  },
  remover: {
    pt: 'Remover',
    en: 'Remove',
    de: 'Löschen',
    es: 'Retirar',
  },
  RemoverAlerta: {
    pt: 'Deseja realmente remover esse alerta?',
    en: 'Do you really want to remove this alert?',
    de: 'Möchten Sie diese Warnung wirklich entfernen?',
    es: '¿Realmente desea eliminar esta alerta?',
  },
  voltar: {
    pt: 'Voltar',
    en: 'Go back',
    de: 'Geh zurück',
    es: 'Regresa',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'kündiger',
    es: 'Cancelar',
  },
  cadastrar: {
    pt: 'Salvar',
    en: 'Save',
    de: 'Speichern',
    es: 'Salvar',
  },
  nome: {
    pt: 'Nome',
    en: 'Name',
    de: 'Name',
    es: 'Nombre',
  },
  descricao: {
    pt: 'Descrição',
    en: 'Description',
    de: 'Beschreibung',
    es: 'Descripción',
  },
};
