export const translations = {
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  novaDivision: {
    pt: 'Nova Division',
    en: 'New Division',
    de: 'Neue Abteilung',
    es: 'Nueva División',
  }, 
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'Auswählen...',
    es: 'Seleccione...',
  },
  descricao: {
    pt: 'Descrição',
    en: 'Description',
    de: 'Beschreibung',
    es: 'Descripción',
  }, 
  cadastrar: {
    pt: 'Cadastrar',
    en: 'Register',
    de: 'Katastern',
    es: 'Register',
  },
  remover: {
    pt: 'Remover',
    en: 'Remove',
    de: 'Löschen',
    es: 'Retirar',
  },
  voltar: {
    pt: 'Voltar',
    en: 'Go back',
    de: 'Geh zurück',
    es: 'Regresa',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'kündiger',
    es: 'Cancelar',
  },
  nome: {
    pt: 'Nome',
    en: 'Name',
    de: 'Name',
    es: 'Nombre',
  },
};