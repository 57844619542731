export const translations = {
  removerTransportadora: {
    pt: 'Ao remover a trasportadora selecionado todas as Notas ficiais vinculadas, não serão mais exibidas na plataforma. Deseja realmente remover?',
    en: 'When removing the selected carrier, all linked Invoices will no longer be displayed on the platform. Do you really want to remove?',
    de: 'Beim Entfernen des ausgewählten Spediteurs werden alle verknüpften Rechnungen nicht mehr auf der Plattform angezeigt. Möchten Sie wirklich entfernen?',
    es: 'Al eliminar el transportista seleccionado, todas las Facturas vinculadas ya no se mostrarán en la plataforma. ¿Realmente quieres eliminar?',
  },
  configuracoes: {
    pt: 'Configurações',
    en: 'Settings',
    de: 'Einstellungen',
    es: 'Configuración',
  },
  gerencia: {
    pt: 'Gerencia Cargas',
    en: 'Manage Cargo',
    de: 'Fracht verwalten',
    es: 'Administrar carga',
  },
  editarTransportadora: {
    pt: 'Editar transportadora',
    en: 'Edit carrier',
    de: 'Träger bearbeiten',
    es: 'Editar portador',
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'Auswählen...',
    es: 'Seleccione...',
  },
  codigoSAP: {
    pt: 'Código SAP',
    en: 'SAP code',
    de: 'SAP Code',
    es: 'código SAP',
  },
  nomeFantasia: {
    pt: 'Nome Fantasia',
    en: 'Fantasy name',
    de: 'Fantasiename',
    es: 'Nombre de fantasía',
  },
  tipo: {
    pt: 'Tipo',
    en: 'Type',
    de: 'Art',
    es: 'Tipo',
  },
  inscricaoEstatual: {
    pt: 'Inscrição Estadual',
    en: 'State registration',
    de: 'Staatliche Registrierung',
    es: 'Registro estatal',
  },
  telefone: {
    pt: 'Telefone',
    en: 'Telephone',
    de: 'Telefon',
    es: 'Teléfono',
  },
  cnpj: {
    pt: 'CNPJ',
    en: 'CNPJ',
    de: 'CNPJ',
    es: 'CNPJ',
  },
  razaoSocial: {
    pt: 'Razão Social',
    en: 'Social Reason',
    de: 'Soziale Vernunft',
    es: 'Razón Social',
  },
  uf: {
    pt: 'UF',
    en: 'Federative unit',
    de: 'Föderative Einheit',
    es: 'Unidad federativa',
  },
  lougradouro: {
    pt: 'Logradouro',
    en: 'Public place',
    de: 'Öffentlicher Platz',
    es: 'Lugar público',
  },
  numero: {
    pt: 'Número',
    en: 'Number',
    de: 'Nummer',
    es: 'Número',
  },
  bairro: {
    pt: 'Bairro',
    en: 'Número',
    de: 'Gegend',
    es: 'Barrio',
  },
  cidade: {
    pt: 'Cidade',
    en: 'City',
    de: 'Stadt',
    es: 'Ciudad',
  },
  latitude: {
    pt: 'Latitude',
    en: 'Latitude',
    de: 'Breite',
    es: 'Latitud',
  },
  longitude: {
    pt: 'Longitude',
    en: 'Longitude',
    de: 'Längengrad',
    es: 'Longitud',
  },
  emails: {
    pt: 'E-mails',
    en: 'E-mails',
    de: 'E-mails',
    es: 'Correos electrónicos',
  },
  cep: {
    pt: 'Cep',
    en: 'Zip code',
    de: 'Postleitzahl',
    es: 'Código postal',
  },
  limparFiltro: {
    pt: 'Limpar Filtro',
    en: 'Clear Filter',
    de: 'Klarfilter',
    es: 'Filtro limpio',
  },
  freteValor: {
    pt: 'Valor de Frete',
    en: 'Shipping Value',
    de: 'Versandwert',
    es: 'Valor de envío',
  },
  fretePeso: {
    pt: 'Frete Peso',
    en: 'Shipping Weight',
    de: 'Versandgewicht',
    es: 'Peso de envío',
  },
  seguro: {
    pt: 'Ad Valoren',
    en: 'Ad Valoren',
    de: 'Ad Valoren',
    es: 'Ad Valoren',
  },
  pedagio: {
    pt: 'Pedágio',
    en: 'Toll',
    de: 'Maut',
    es: 'Peaje',
  },
  cancelar: {
    pt: 'Cancelar',
    en: 'Cancel',
    de: 'Abbrechen',
    es: 'Cancelar',
  },
  filtrar: {
    pt: 'Filtrar',
    en: 'Filter',
    de: 'Filter',
    es: 'Filtrar',
  },
  voltar: {
    pt: 'Voltar',
    en: 'Go back',
    de: 'Geh zurück',
    es: 'Regresa',
  },
  cadastrar: {
    pt: 'Salvar',
    en: 'Save',
    de: 'Speichern',
    es: 'Ahorrar',
  },
};
