export const translations = {
  configuracoes: {
    pt: "Configurações",
    en: "Settings",
    de: "Einstellungen",
    es: "Configuración",
  },
  editar: {
    pt: "Editar Usuário",
    en: "Edit User",
    de: "Benutzer bearbeiten",
    es: "Editar Usuário",
  },
  editarF: {
    pt: "Editar",
    en: "Edit",
    de: "Bearbeiten",
    es: "Editar",
  },
  nome: {
    pt: "Nome",
    en: "Name",
    de: "Nombre",
    es: "Nome",
  },
  email: {
    pt: "E-mail",
    en: "E-mail",
    de: "Bezeichnung",
    es: "Correo electrónico",
  },
  phone_number: {
    pt: "Celular",
    en: "Cell phone",
    de: "Handy",
    es: "Teléfono móvil",
  },
  csr: {
    pt: "CSR",
    en: "CSR",
    de: "CSR",
    es: "CSR",
  },
  senha: {
    pt: "Senha",
    en: "Password",
    de: "Seña",
    es: "Passwort",
  },
  confirmaSenha: {
    pt: "Confirmar senha",
    en: "Confirm password",
    de: "Bestätige das Passwort",
    es: "Confirmar seña",
  },
  tipo: {
    pt: "Tipo",
    en: "Type",
    de: "Typ",
    es: "Escribe",
  },
  proximaExpiracao: {
    pt: "Senha expira em",
    en: "Password expires in",
    de: "Passwort läuft ab",
    es: "La contraseña expira en",
  },
  renovacaoSenha: {
    pt: "Expiração de senha",
    en: "Password expiration",
    de: "Passwortablauf",
    es: "Expiración de contraseña",
  },
  ultimoAcesso: {
    pt: "Último acesso",
    en: "Last access",
    de: "Letzter Zugriff",
    es: "Último acceso",
  },
  transportadora: {
    pt: "Transportadora",
    en: "Carrier",
    de: "Träger",
    es: "Portador",
  },
  centro: {
    pt: "Centro",
    en: "Center",
    de: "Center",
    es: "Centrar",
  },
  clientes: {
    pt: "Clientes",
    en: "Customers",
    de: "Kunden",
    es: "Clientes",
  },
  customer: {
    pt: "Customer Service",
    en: "Customer Service",
    de: "Kundendienst",
    es: "Servicio al Cliente",
  },
  voltar: {
    pt: "Voltar",
    en: "Go back",
    de: "Geh zurück",
    es: "Regresa",
  },
  bloquear: {
    pt: "Bloquear acesso a aplicação WEB",
    en: "Block access to the WEB application",
    de: "Zugriff auf die WEB-Anwendung sperren",
    es: "Bloquear el acceso a la aplicación WEB",
  },
  acesso: {
    pt: "Acesso ao aplicativo",
    en: "App access",
    de: "App-Zugriff",
    es: "Acceso a la aplicación",
  },
  saraAcesso: {
    pt: "Acesso a Sara",
    en: "Sara access",
    de: "Sara-Zugriff",
    es: "Acceso a la Sara",
  },
  emailSeg: {
    pt: "E-mails Secundário",
    en: "Secondary Emails",
    de: "Sekundäre E-Mails",
    es: "Correos electrónicos secundarios",
  },
  emailOutros: {
    pt: "Adicione outros e-mails para o recebimento de alertas.",
    en: "Add other emails to receive alerts.",
    de: "Agregue otros correos electrónicos para recibir alertas.",
    es: "Fügen Sie andere E-Mails hinzu, um Benachrichtigungen zu erhalten.",
  },
  adicionar: {
    pt: "Adicionar",
    en: "To add",
    de: "Hinzufügen",
    es: "Para agregar",
  },
  remover: {
    pt: "Remover",
    en: "Remove",
    de: "Löschen",
    es: "Retirar",
  },
  cancelar: {
    pt: "Cancelar",
    en: "Cancel",
    de: "kündiger",
    es: "Cancelar",
  },
  descricao: {
    pt: "Descrição",
    en: "Description",
    de: "Beschreibung",
    es: "Descripción",
  },
  modulos: {
    pt: "Modulos",
    en: "Modules",
    de: "Module",
    es: "Modulos",
  },
  limparFiltro: {
    pt: "Limpar Filtro",
    en: "Clear Filter",
    de: "Klarfilter",
    es: "Filtro limpio",
  },
  filtrar: {
    pt: "Filtrar",
    en: "Filter",
    de: "Filter",
    es: "Filtrar",
  },
  cadastrar: {
    pt: "Salvar",
    en: "Save",
    de: "Speichern",
    es: "Ahorrar",
  },
  permissao: {
    pt: "Grupos de permissões",
    en: "Permission groups",
    de: "Berechtigungsgruppen",
    es: "Grupos de permisos",
  },
  selectGrupo: {
    pt: "Selecione os grupos de permissões adicionais ao usuário.",
    en: "Select additional user permission groups.",
    de: "Wählen Sie zusätzliche Benutzerberechtigungsgruppen aus.",
    es: "Seleccionar grupos de permisos de usuario adicionales.",
  },
  restringir: {
    pt: "Restringir acesso:",
    en: "Restrict access:",
    de: "Zugriff einschränken:",
    es: "Acceso restringido:",
  },
  restringirCentro: {
    pt: "Adicione os centros que o usuário poderá acessar. Se deixar em branco o usuário terá acesso a todos os centros, pois não haver nenhuma restrição cadastrada.",
    en: "Add the centers that the user will be able to access. If left blank, the user will have access to all centers, as there is no restriction registered.",
    de: "Fügen Sie die Center hinzu, auf die der Benutzer zugreifen kann. Wenn das Feld leer gelassen wird, hat der Benutzer Zugriff auf alle Zentren, da keine Einschränkung registriert ist.",
    es: "Añade los centros a los que el usuario podrá acceder. Si se deja en blanco, el usuario tendrá acceso a todos los centros, ya que no hay ninguna restricción registrada.",
  },
  selecione: {
    pt: "Selecione...",
    en: "Select...",
    de: "Auswählen...",
    es: "Seleccione...",
  },
  aprovbl: {
    pt: "Aprovação por NR:",
    en: "Approval by RN:",
    de: "Zulassung durch RN:",
    es: "Aprobación por NR:",
  },
  adicionebl: {
    pt: "Adicione os NR's que o usuário poderá aprovar justificativasinformadas pelas transportadoras.",
    en: "Add the RN's that the user will be able to approve justificationsreported by the carriers.",
    de: "Fügen Sie die RN hinzu, damit der Benutzer die von den Spediteuren mitgeteilten Begründungen genehmigen kann.",
    es: "Agregar los NR's que el usuario podrá aprobar justificaciones informadas por los transportistas.",
  },
  expiraPlace: {
    pt: "Selecione o intervalo de expiração da senha.",
    en: "Select the password expiration interval.",
    de: "Wählen Sie das Passwort-Ablauf-Intervall aus.",
    es: "Seleccione el intervalo de expiración de la contraseña.",
  },
  naoExpira: {
    pt: "Não expirar senha",
    en: "Do not expire password",
    de: "Passwort nicht ablaufen",
    es: "No expirar contraseña",
  },
  expira30: {
    pt: "Expirar a cada 30 dias",
    en: "Expire every 30 days",
    de: "Jedes 30. Tag ablaufen",
    es: "Expirar cada 30 días",
  },
  expira60: {
    pt: "Expirar a cada 60 dias",
    en: "Expire every 60 days",
    de: "Jedes 60. Tag ablaufen",
    es: "Expirar cada 60 días",
  },
  expira180: {
    pt: "Expirar a cada 180 dias",
    en: "Expire every 180 days",
    de: "Jedes 180. Tag ablaufen",
    es: "Expirar cada 180 días",
  },
  pais: {
    pt: "País",
    en: "Country",
    de: "Land",
    es: "País",
  },
};
