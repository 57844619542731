export const translations = {
  codigo: {
    pt: 'Código',
    en: 'Code',
    de: 'Code',
    es: 'Código',
  },
  uf: {
    pt: 'UF',
    en: 'Federative unit',
    de: 'Föderative Einheit',
    es: 'Unidad federativa',
  },
  cidade: {
    pt: 'Cidade',
    en: 'City',
    de: 'Stadt',
    es: 'Ciudad',
  },
  limparFiltro: {
    pt: 'Limpar Filtro',
    en: 'Clear Filter',
    de: 'Klarfilter',
    es: 'Filtro limpio',
  },
  fechar: {
    pt: 'Fechar',
    en: 'Close',
    de: 'Enge',
    es: 'Cerrar',
  },
  filtrar: {
    pt: 'Filtrar',
    en: 'Filter',
    de: 'Filter',
    es: 'Filtrar',
  },
  nomeFantasia: {
    pt: 'Nome Fantasia',
    en: 'Fantasy name',
    de: 'Fantasiename',
    es: 'Nombre de fantasía',
  },
  cnpj: {
    pt: 'CNPJ',
    en: 'CNPJ',
    de: 'CNPJ',
    es: 'CNPJ',
  },
  segmentacao: {
    pt: 'Segmentação de clientes',
    en: 'Customer segmentation',
    de: 'Kundensegmentierung',
    es: 'Segmentación de clientes',
  },
  razaoSocial: {
    pt: 'Razão Social',
    en: 'Social Reason',
    de: 'Soziale Vernunft',
    es: 'Razón Social',
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'Auswählen...',
    es: 'Seleccione...',
  },
};