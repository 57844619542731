import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";

import { useDispatch } from "react-redux";

import { useHistory } from "react-router-dom";
import { usePermissions, useTranslation } from "hooks";

import { translations } from "./translations";
import * as S from "./styles";

import { SelectClean } from "components/shared/Form";

import { SelectedFilterActions } from "store/ducks/filter";
interface IMenuProps {
  page: String;
}

interface IOptionTypes {
  label: string;
  value: string;
  hasPermissions: string[];
}

export const MenuSelector: React.FC<IMenuProps> = ({ page }) => {
  const formMenu = useRef<FormHandles>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { getTranslation } = useTranslation(translations);
  const [settingOption, setSettingOption] = useState<IOptionTypes[]>([]);
  const { checkUserEveryPermission } = usePermissions();

  const options: IOptionTypes[] = useMemo(
    () => [
      {
        label: getTranslation("alertas"),
        value: "alerts",
        hasPermissions: ["accessConfigurator", "listAlert"],
      },
	  {
		label: getTranslation("historicoDeAlertas"),
		value: "alert-email-logs",
		hasPermissions: ["accessConfigurator", "historyAlert"],
	  },
      {
        label: getTranslation("centro"),
        value: "companies",
        hasPermissions: ["accessConfigurator", "listCompany"],
      },
      {
        label: getTranslation("segmentacao"),
        value: "client-types",
        hasPermissions: ["accessConfigurator", "listClientType"],
      },
      {
        label: getTranslation("clientes"),
        value: "clients",
        hasPermissions: ["accessConfigurator", "listClient"],
      },
      {
        label: getTranslation("produtos"),
        value: "products",
        hasPermissions: ["accessConfigurator", "listProduct"],
      },
      {
        label: getTranslation("tiposDeProduto"),
        value: "product-types",
        hasPermissions: ["accessConfigurator", "listProductType"],
      },
      {
        label: getTranslation("unidadeDeNegocio"),
        value: "business-units",
        hasPermissions: ["accessConfigurator", "listBusinessUnit"],
      },
      {
        label: getTranslation("linhaDeNegocio"),
        value: "business",
        hasPermissions: ["accessConfigurator", "listBusinessLine"],
      },
      {
        label: getTranslation("transportadora"),
        value: "carriers",
        hasPermissions: ["accessConfigurator", "listCarriers"],
      },
      {
        label: getTranslation("tiposDepallet"),
        value: "pallet-types",
        hasPermissions: ["accessConfigurator", "listPallets"],
      },
      {
        label: getTranslation("usuarios"),
        value: "users",
        hasPermissions: ["accessConfigurator", "listUser"],
      },
      {
        label: getTranslation("veiculos"),
        value: "vehicles",
        hasPermissions: ["accessConfigurator", "listVehicle"],
      },
      {
        label: getTranslation("tiposDeVeiculos"),
        value: "vehicle-types",
        hasPermissions: ["accessConfigurator", "listVehicleType"],
      },
      {
        label: getTranslation("perfilUsuario"),
        value: "groups",
        hasPermissions: ["accessConfigurator", "listProfileUser"],
      },
      {
        label: getTranslation("permissoes"),
        value: "permissions",
        hasPermissions: ["accessConfigurator", "listPermission"],
      },
    ],
    [getTranslation]
  );

  const handleOption = useCallback(
    (route) => {
      dispatch(SelectedFilterActions.reset());
      history.push(`/settings/${route.value}`);
    },
    [history, dispatch]
  );

  const checkPagePermission = useCallback(
    (slugPage) => {
      const pageOptions = options.filter((option) =>
        checkUserEveryPermission(option.hasPermissions)
      );

      setSettingOption(pageOptions);

      if (formMenu.current) {
        if (pageOptions.length && !slugPage) {
          formMenu.current.setData({
            menuSelect: {
              value: pageOptions[0].value,
              label: pageOptions[0].label,
            },
          });
          handleOption(pageOptions[0]);
        }

        if (pageOptions.length && slugPage) {
          const optionPage = pageOptions.find(
            (option: any) => option.value === slugPage
          );

          if (optionPage) {
            formMenu.current.setData({
              menuSelect: { value: optionPage.value, label: optionPage.label },
            });
          }
        }
      }
    },
    [handleOption, checkUserEveryPermission, options]
  );

  useEffect(() => {
    if (!settingOption.length) {
      checkPagePermission(page);
    }
  }, [checkPagePermission, page, settingOption]);

  return (
    <S.Container>
      <Form ref={formMenu} onSubmit={handleOption}>
        <SelectClean
          name="menuSelect"
          options={settingOption}
          onChange={(page) => handleOption(page)}
          placeholder="Menu"
        />
      </Form>
    </S.Container>
  );
};
