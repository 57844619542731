export const translations = {
  codCentro: {
    pt: 'cod. Centro',
    en: 'cod. Center',
    de: 'Codezentrum',
    es: 'cod. Centro',
  },
  descCentro: {
    pt: 'Desc. Centro',
    en: 'Desc. Center',
    de: 'Beschr. Center',
    es: 'Desc. Centrar',
  },
  deletar: {
    pt: 'Deletar',
    en: 'Delete',
    de: 'löschen',
    es: 'Eliminar',
  },
  businessLine: {
    pt: 'NR',
    en: 'RN',
    de: 'RN',
    es: 'NR',
  },
};