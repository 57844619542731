export const translations = {
  obrigatorio: {
    pt: 'Obrigtório',
    en: 'Mandatory',
    de: 'Verpflichtend',
    es: 'Obligatorio',
  },
  codigoSAP: {
    pt: 'Cód. SAP',
    en: 'SAP code',
    de: 'SAP Code',
    es: 'Cód. SAP',
  },
  cnpjRaiz: {
    pt: 'Raíz CNPJ',
    en: 'CNPJ root',
    de: 'CNPJ Wurzel',
    es: 'Raíz CNPJ',
  },
  customizacao: {
    pt: 'Customer Seg.',
    en: 'Customer Mon.',
    de: 'Kunde Mo.',
    es: 'Cliente lun.',
  },
  razaoSocial: {
    pt: 'Razão Social',
    en: 'Social Reason',
    de: 'Soziale Vernunft',
    es: 'Razón Social',
  },
  excessao: {
    pt: 'Excessões de Filial',
    en: 'Branch Excesses',
    de: 'Soziale Vernunft',
    es: 'Excesos de sucursales',
  },
  businessLine: {
    pt: 'Nome Regional',
    en: 'Regional Name',
    de: 'Regionaler Name',
    es: 'Nombre Regional',
  },
  adicionar: {
    pt: 'Adicionar',
    en: 'To add',
    de: 'Hinzufügen',
    es: 'Para agregar',
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'Auswählen...',
    es: 'Seleccione...',
  },
};