export const translations = {
  descricao: {
    pt: 'Descrição',
    en: 'Description',
    de: 'Beschreibung',
    es: 'Descripción',
  },
  codigo: {
    pt: 'Código',
    en: 'Code',
    de: 'Code',
    es: 'Código',
  },
  businessLine: {
    pt: 'NR',
    en: 'RN',
    de: 'RN',
    es: 'NR',
  },
};