
export const translations = {
  id: {
    pt: 'Id',
    en: 'Id',
    de: 'id',
    es: 'Id',
  },
   codigo: {
     pt: 'Código',
     en: 'Code',
     de: 'Code',
     es: 'Código',
   },
   limparFiltro: {
     pt: 'Limpar Filtro',
     en: 'Clear Filter',
     de: 'Klarfilter',
     es: 'Filtro claro',
   },
   fechar: {
    pt: 'Fechar',
    en: 'Close',
    de: 'Enge',
    es: 'Cerrar',
  },
   filtrar: {
     pt: 'Filtrar',
     en: 'Filter',
     de: 'Filter',
     es: 'Filtrar',
   },
   nomeFantasia: {
     pt: 'Nome Fantasia',
     en: 'Fantasy name',
     de: 'Fantasiename',
     es: 'Nombre de fantasía',
   },
   cnpj: {
     pt: 'CNPJ',
     en: 'CNPJ',
     de: 'CNPJ',
     es: 'CNPJ',
   },
   tipos: {
    pt: 'Tipos',
    en: 'Types',
    de: 'Typen',
    es: 'Tipos',
  },
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'Auswählen...',
    es: 'Seleccione...',
  },
};
