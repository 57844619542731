import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { User, LanguageSelector } from 'components/shared';
import { usePermissions, useTranslation } from 'hooks';
import { translations } from './translations';
import * as S from './styles';

interface IModule {
  icon: any;
  name: string;
  description: string;
  active: boolean;
  innerUrl?: string;
  url?: string;
}

const baseUrl =
  window.location.hostname === 'localhost'
    ? 'http://localhost:3000/'
    : 'https://agis.webcol.systems/';

export const AsideMenu: React.FC = () => {
  const history = useHistory();
  const { checkUserPermission, checkModulePermission } = usePermissions();
  const { getTranslation } = useTranslation(translations);

  const modules: IModule[] = [
    {
      icon: <S.IconControlTower />,
      name: getTranslation('controlTower'),
      description: getTranslation('descControlTower'),
      active: checkUserPermission('accessControlTower'),
      innerUrl: '/control-tower',
    },
    {
      icon: <S.IconTracking />,
      name: getTranslation('Tracking'),
      description: getTranslation('descTracking'),
      active: checkUserPermission('accessTracking') || checkModulePermission(1),
      url: baseUrl + 'tracking/',
    },
    {
      icon: <S.IconSettings />,
      name: getTranslation('configurador'),
      description: getTranslation('descConfigurador'),
      active: checkUserPermission('accessConfigurator'),
      innerUrl: '/settings',
    },
  ];

  const handleNavigation = useCallback(
    (module: IModule) => {
      if (module.active) {
        if (module.innerUrl) return history.push(module.innerUrl);
        if (module.url) return window.open(module.url);
      }
    },
    [history]
  );

  return (
    <S.Container>
      <S.Content>
        <button onClick={()=>history.push('')}>
          <S.Logo />
        </button>
        <LanguageSelector />
        <User />
        {modules.map((module) => (
          <S.ButtonModule
            active={module.active}
            onClick={() => handleNavigation(module)}
            key={module.innerUrl || module.url}
          >
            <S.ModuleTitle>
              {module.icon}
              {module.name}
            </S.ModuleTitle>
            <S.ModuleDescription>{module.description}</S.ModuleDescription>
          </S.ButtonModule>
        ))}
      </S.Content>
    </S.Container>
  );
};
