export const translations = {
  selecione: {
    pt: 'Selecione...',
    en: 'Select...',
    de: 'auswählen...',
    es: 'Seleccione...',
  },
  obrigatorio: {
    pt: 'Obrigtório',
    en: 'Mandatory',
    de: 'Verpflichtend',
    es: 'Obligatorio',
 },
  excess: {
    pt: 'Excessões',
    en: 'Exceptions',
    de: 'Ausnahmen',
    es: 'Excepciones',
  },
  excessao: {
    pt: 'Excessão',
    en: 'Exception',
    de: 'Ausnahme',
    es: 'Excepción',
  },
  adicionar: {
    pt: 'Adicionar',
    en: 'Add',
    de: 'Addieren',
    es: 'Agregar',
  },
};