export enum Colors {
  White = '#FFFFFF',
  Orange = '#EE7C2C',
  Orange20 = '#F5E9E1',
  Gray05 = '#f8f8f8',
  Gray10 = '#E9E9E9',
  Gray20 = '#D1D1D1',
  Gray30 = '#88888C',
  Gray40 = '#777777',
  Gray50 = '#444444',
  Gray100 = '#1E293B',
  Red = '#E01D48',
  Green = '#16A24A',
  Black = "#2B2B2B",
  Blue = "#3b81f5"
}
