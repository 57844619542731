export const translations = {
  controlTower: {
    pt: 'Control Tower',
    en: 'Control Tower',
    de: 'Control Tower',
    es: 'Control Tower',
  },
  descControlTower: {
    pt: 'Eficiência na operação logística.',
    en: 'Efficiency in logistics operations.',
    de: 'Effizienz in Logistikbetrieb.',
    es: 'Eficiencia en operaciones logísticas.',
  },
  roterizador: {
    pt: 'Roterizador',
    en: 'Scripter',
    de: 'Skripter',
    es: 'Guionista',
  },
  descRoterizador: {
    pt: 'Otimização na geração de cargas.',
    en: 'Load generation optimization.',
    de: 'Optimierung der Lastgenerierung.',
    es: 'Optimización de la generación de carga.',
  },
  agendamento: {
    pt: 'Agendamento',
    en: 'Scheduling',
    de: 'Terminplanung',
    es: 'Planificación',
  },
  descAgendamento: {
    pt: 'Agendamento e gestão das cargas roterizadas.',
    en: 'Scheduling and management of routed loads.',
    de: 'Planung und Verwaltung von gerouteten Lasten.',
    es: 'Programación y gestión de cargas enrutadas.',
  },
  Tracking: {
    pt: 'Tracking',
    en: 'Tracking',
    de: 'Verfolgung',
    es: 'Seguimiento',
  },
  descTracking: {
    pt: 'Monitoramento e gestão de entregas em tempo real.',
    en: 'Monitoring and management of deliveries in real time.',
    de: 'Überwachung und Verwaltung von Lieferungen in Echtzeit.',
    es: 'Seguimiento y gestión de entregas en tiempo real.',
  },

  CheckFretes: {
    pt: 'CheckFretes',
    en: 'CheckFreights',
    de: 'Verfolgung',
    es: 'Comprobar fletes',
  },
  descCheckFretes: {
    pt: 'Gestão total dos fretes por transportadora.',
    en: 'Total management of freight by carrier.',
    de: 'Vollständige Verwaltung der Fracht durch den Spediteur.',
    es: 'Gestión total del flete por transportista.',
  },
  descComex: {
    pt: 'Gestão das importações com previsibilidade de entrega.',
    en: 'Management of imports with predictable delivery.',
    de: 'Management von Importen mit vorhersehbarer Lieferung.',
    es: 'Gestión de importaciones con entrega predecible.',
  },
  configurador: {
    pt: 'Configurador',
    en: 'Settings',
    de: 'Konfiguration',
    es: 'Configuraciones',
  },
  descConfigurador: {
    pt: 'Gerenciameto de acessos e parametrização do sistema.',
    en: 'Management of access and parameterization of the system.',
    de: 'Zugriffsverwaltung und Parametrierung des Systems.',
    es: 'Gestión de accesos y parametrización del sistema.',
  },
};
