export const translations = {
  descricao: {
    pt: 'Descrição',
    en: 'Description',
    de: 'Beschreibung',
    es: 'Descripción',
  },
  setor: {
    pt: 'Setor de atividade divisão',
    en: 'Division of activity',
    de: 'Aufteilung der Tätigkeit',
    es: 'División de actividad',
  },
  unidade: {
    pt: 'Unidade',
    en: 'Unit',
    de: 'Einheit',
    es: 'Unidad',
  },
};