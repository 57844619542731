import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 32px;
  background-color: #3b81f5;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 40px;
`;
